

export const environment = {
    production: false,
    memorialUrl: 'https://eternallyme.dev.frontend.urmbrme.com/#/view-information/',
    apiUrl: 'https://eternallyme.dev.backend.urmbrme.com/api/v1/',
    apiAdminUrl: 'https://eternallyme.dev.backend.urmbrme.com/api/v1/admin/',
    tinyMceKey: '86vzyixdap7vmy9zloatrcxbkv5dvgry6zv59v5vx1co73t3'
    
};

